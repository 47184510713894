<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  methods: {},
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

html,
body,
input {
  font-family: "PingFang SC", "Microsoft YaHei", "DejaVu Sans", Tahoma,
    "Hiragino Sans GB", STHeiti, "Microsoft YaHei";
}

input {
  border: none;
  outline: none;
  font-size: 14px;
  padding: 0 10px;
}

button {
  width: 72px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #eee;
  margin-right: 16px;
  color: #121212;
  font-size: 14px;
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #999;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}

// elementUI
.el-select-dropdown__item.selected span {
  color: #0f6eef;
}

.el-input__inner,
.el-input__inner:focus {
  border-color: #eee;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  width: 40px !important;
  height: 40px !important;
  font-size: 16px !important;
  color: #333333 !important;
  border-radius: 8px !important;
  border: solid 1px #eeeeee !important;
  background-color: transparent !important;
  line-height: 40px !important;
  font-weight: normal;
}

.el-pagination.is-background .btn-next:hover,
.el-pagination.is-background .btn-prev:hover,
.el-pagination.is-background .el-pager li.active,
.el-pagination.is-background .el-pager li:hover {
  border: solid 1px #0f6eef !important;
  color: #0f6eef !important;
  background-color: #f5f7fe !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  border: solid 1px #0f6eef !important;
  color: #fff !important;
  background-color: #0f6eef !important;
  outline: none;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #0f6eef !important;
}
.el-checkbox__inner,
.el-checkbox__inner:hover {
  border: 0.5px solid #0f6eef !important;
}

.main {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  padding: 32px;
  padding-left: 0;
  background-color: #f5f7fe;

  .mainContent {
    flex: 1;
    width: calc(100% - 192px);

    // 查询条件 查询
    .categoryBox {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;

      > span {
        font-size: 24px;
        color: #121212;
        font-weight: bold;
        display: flex;
        align-items: center;
        img {
          width: 139px;
          height: 36px;
          margin-left: 20px;
        }
      }

      .box {
        display: flex;

        > div {
          width: 108px;
          height: 40px;
          background-color: #0f6eef;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 20px;

          img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }

          span {
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
          }
        }
      }
    }

    //	表格列表
    .tableMain {
      width: 100%;
      height: calc(100% - 60px);
      background-color: #fff;
      border-radius: 8px;
      padding: 32px;

      .table-searchBox {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          color: #121212;
          margin-right: 10px;
          white-space: nowrap;
        }

        .searchInput {
          width: 10%;
          max-width: 200px;
          height: 40px;
          border-radius: 2px;
          margin-right: 20px;
        }

        .searchInput[type="text"] {
          border: solid 1px #eeeeee;
          border-radius: 4px;
        }

        .cxBtn {
          background-color: #0f6eef;
          color: #fff;
        }
      }

      .tableList {
        width: calc(100vw - 288px);
        margin-top: 32px;

        .cell {
          padding: 0 0 0 32px;
        }

        .cell:first-child button {
          padding-left: 0;
        }

        .td.el-table__cell {
          border-top: 1px solid transparent;
        }

        th.el-table__cell {
          background-color: #dacbb8;
          color: #121212;
        }

        .el-button--text {
          color: #e18600;
          font-size: 14px;
        }

        button {
          flex: 1;
          width: auto;
          padding: 0 10px;
        }
      }

      .tablePage {
        margin-top: 12px;
        width: 100%;
        text-align: center;
      }
    }
  }
}

// 技术支持
.jszc {
  width: 100%;
  height: auto;
  display: inline-block;
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 0;
  font-size: 16px;
  color: #4b311f;
}

// 弹出框样式
.maskPop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  .addForm {
    width: 30vw;
    padding: 32px;
    background-color: #fff;
    border-radius: 8px;

    .formTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      span {
        font-size: 16px;
        color: #121212;
        font-weight: bold;
      }

      img {
        width: 14px;
        height: 14px;
        padding: 8px;
        cursor: pointer;
        box-sizing: content-box;
      }
    }

    .inputTitle {
      display: flex;
      margin-top: 14px;
      font-size: 14px;

      p {
        line-height: 40px;
        min-width: 100px;
        color: #121212;

        span {
          color: #ff6969;
        }
      }

      input {
        // width: 200px;
        flex: 1;
        height: 40px;
        background-color: #ffffff;
        border-radius: 2px;
        border: solid 1px #eeeeee;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: #121212;
        line-height: 40px;
      }

      input[disabled] {
        background-color: #f5f7fa;
      }

      .cxBtn {
        margin-left: 10px;
      }

      .el-checkbox-group {
        width: 100%;

        .el-checkbox {
          width: 100%;
          display: block;
          line-height: 40px;
          margin-right: 0;
          font-size: 16px;

          .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #0f6eef;
            outline: 1rpx solid #0f6eef;
          }
        }
      }
    }

    .formBtn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;

      button {
        width: 72px;
        height: 40px;
        border-radius: 4px;
        background-color: #ffffff;
        border: solid 1px #eeeeee;
        outline: none;
      }

      .bc {
        background-color: #0f6eef;
        color: #fff;
      }
    }
  }
}

.el-form-item__label {
  color: #121212 !important;
}

.base-container .el-checkbox__label {
  color: #121212 !important;
}

.nameClass {
  position: relative;
  cursor: pointer;
}

.nameClass::after {
  position: absolute;
  content: " ";
  width: 12px;
  height: 10px;
  background-image: url(./assets/images/icon_ss.png);
  background-size: 100% 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.showTree::after {
  transform: translateY(-50%) rotate(180deg);
}

.el-table .searchRow {
  // background-color: rgba(15,110,239,.2);
  // box-shadow: 0px 0px 25px 0px #0f6eef inset;
  color: #0f6eef;
}
.main .mainContent .tableMain .table-searchBox .cxBtn,
.main .mainContent .categoryBox .box > div,
.el-form-item__content .el-button {
  background-color: #0f6eef !important;
  border: none;
}

.main .mainContent .tableMain .tableList th.el-table__cell {
  background-color: rgba(15, 110, 239, 0.7);
}
.user-container .tabBox .tabItem.active,.base-container .tabBox .tabItem.active {
  border-bottom: 1px solid #0f6eef !important;
  color: #0f6eef !important;
  
}
</style>
