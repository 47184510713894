import Vue from 'vue'
import { Select, Option, DatePicker, Table, TableColumn, Pagination, Button, Form, FormItem, Input, Checkbox, CheckboxButton, CheckboxGroup, Tabs, TabPane,MessageBox,Message,Loading, Tree, Upload, Notification } from 'element-ui'

// 上传文件
Vue.use(Upload)

// 选择器
Vue.use(Select)
Vue.use(Option)

// 表格
Vue.use(Table)
Vue.use(TableColumn)

// 分页器
Vue.use(Pagination)

// 按钮
Vue.use(Button)

//表单
Vue.use(Form)
Vue.use(FormItem)

//输入框
Vue.use(Input)

//复选框
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)

// 标签页
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading)
Vue.use(Tree)
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;