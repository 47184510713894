import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import App from './App.vue'
import router from './router'
import 'lib-flexible'
import './plugins/element.js'
import 'amfe-flexible/index.js'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import VueCookies from 'vue-cookies'
import JSEncrypt from 'jsencrypt';
Vue.prototype.$jsEncrypt = function(code){
	// 新建JSEncrypt对象
	var jsEncrypt = new JSEncrypt();
	// 设置公钥
	jsEncrypt.setPublicKey(`MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmou7/Gbef0fQ9zxy4l/XHbACwz+iFk+lbTI5Pn24M4HdosGvNcZY9hZq4hYHC4DL5i/JmO3S62Nk+LtAYTxa12yVLvwVpX76TaiijyBDcnSgmErXwxSfd47TdUna/xBVtlY3bcYPi2aKLNcVeu8CIljQlWVlNskAL1q5uXgWO0H2BbFyMSUdD4h/hKo4rKqquqSSRjiV5K5+WF4C+J39O2QAvMbO7hxd0wsFw6prGchbv+Ia9C5mWkVOSR7/VBCffpFLb9anGdG7eVbOsXfSQEUr9gWAZDh81mRCbLDYuiJD2bXsq2/elWVCaDAc31bcm18S6lIvklzFLwTYpB32PQIDAQAB`);
	let data = jsEncrypt.encrypt(JSON.stringify(code));
	return data;
};
Vue.prototype.$jsDecrypt = function(code){
	// 新建JSEncrypt对象
	var jsDecrypt = new JSEncrypt();
	// 设置私钥
	jsDecrypt.setPrivateKey(`MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCai7v8Zt5/R9D3PHLiX9cdsALDP6IWT6VtMjk+fbgzgd2iwa81xlj2FmriFgcLgMvmL8mY7dLrY2T4u0BhPFrXbJUu/BWlfvpNqKKPIENydKCYStfDFJ93jtN1Sdr/EFW2Vjdtxg+LZoos1xV67wIiWNCVZWU2yQAvWrm5eBY7QfYFsXIxJR0PiH+Eqjisqqq6pJJGOJXkrn5YXgL4nf07ZAC8xs7uHF3TCwXDqmsZyFu/4hr0LmZaRU5JHv9UEJ9+kUtv1qcZ0bt5Vs6xd9JARSv2BYBkOHzWZEJssNi6IkPZteyrb96VZUJoMBzfVtybXxLqUi+SXMUvBNikHfY9AgMBAAECggEAIKHMqbjW1i1F1E5xO5ddBqm5i5yODwP2PB5Us/s7v7R2Jf2bj1E4S43kN6TLDATACPSiLqZY2Nb95P800tM5oy8MOQWFVJKBK5Cl8m6vjyVNG5sB//EE0uvXfnZ/xUefsYZ24bjmY1rdZiZsJK7+1l3GfSURwAWlAlq3yI+wsgYhXlpfYl+G4Qk47gC8C9qZ6+AlLCKquHgCcM2bnzIqvl514DkmPnpAQNodkaTwtfpx/sv/XuKSv1VKly/hweJHCRSuQzPScqSZhK+zH09bjG5M1JdobWecUjNtaoFSduW3q+3l6GHuk5uFAIZZSSbPLLNk3Nnf1fB1wcEn3katAQKBgQDRa4KoOzxWucOZqyFVeVv9NWWRjMnYQOwl15U5HrBPJzOgEgbd78/l0/IqCAF9gfQwDx18rKvXHBHB/KAn0JPFF96qfRH/qg7O1RMgIBJxQ2giO7TzbF+62Oa2z5H6aFM92SzTdFWzRVM5nuHY6sk6gYFBrU4cNdSlrU1EZ2qRHQKBgQC866kdRRJNVga4gKsADJgD3mB30h1AfHO952IbWLFfL5VYSt1r4DV6ffY4DSkWiNwNU++uw8rmhtSuvI70yNx64VHQH/rm4dlE5hngPVxyMkspkWODOOQooWc0aDKSPpDHlyG5h3ffELA7smo9mcEq5cK0aUD21pZ8CeVHzngPoQKBgQDEBk+R64iqYU4PLFmSaXvKw02kdVWGgBbLwUUj527/WGGBhv44hj5jEgZOg5npglgxGCTwy6w6OUMCAtdjDWEUjyhVC6dqWiNsRZSWcI7yyDbUPHE/ngTpoBgtrJQnAbpRXVURRYbmvJCPkgIzimn7wrdb474Gt/ipcFOwIOFmnQKBgHzYSzjBhXe/6s8QkHCG4gpkcWGdY6FS3PXZK5cgxI4/lrTzPGHml9Cr3wNphwaSSMEx/8mBL9uprHEQwHP5LOKzdepQx/C9vWPvn5f1VNtOayc8IgP8m6JSDy6t0QWd/PU45ZuCb2/kymGY5OZybhnYHhQ1Hi5A3s93hqIuSl8hAoGABtBVdqI+6gV+9+jjIV8tw3XS4hT62+YrmuSXRFSRtlFcigAfOXHSDjb7WTGmauX4K89YfL7qBmu5K65xUQwpK8/9KTwbqPoMWPrUE9k9eTvhMS2Os3KfxNDFtdOVZiaXfPahBBFxf/Ntu9aUKMaLcdv9f3TyAYZbv+H5Z9J58Rg=`);
	let data = jsDecrypt.decrypt(code);
	return data;
};

Vue.use(VueCookies)

let titleObj = {
  'login': '登录',
  'index': '集合页',
  'permit/userlist': '用户管理',
  'permit/rolelist': '角色管理',
  'permit/modulelist': '模块管理',
  'permit/systemlist': '系统管理',
  'permit/contSystem': '可控系统',
  'dept/deptlist': '本系统',
  'dept/deptddlist': '浙政钉',
  'set/user': '个人资料',
  'set/base': '基本配置',
  'wxlogin/login': '微信登陆',
  'wxlogin/bind': '微信绑定',
}
router.beforeEach((to, from, next) => {
  document.title = '莫干山-' + titleObj[to.name];
  next();
});
      
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')




 
